@charset "utf-8";

#in-issue {
    position: absolute;
    display: flex;
    box-sizing: border-box;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}

#in-issue .in-issue-sidebar {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    height: 100%;
    width: 25rem;
    background-color: var(--color1);
}

#in-issue .in-issue-sidebar .top-bar {
    padding: 1rem 0 1rem 2.5rem;
    box-sizing: border-box;
    background-color: var(--common-color1);
}

#in-issue .in-issue-sidebar .metadata {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: auto;
    flex-grow: 1;
    padding: 2.5rem;
}

#in-issue .in-issue-sidebar .metadata .logo img {
    max-width: 100%;
}

#in-issue .in-issue-sidebar .metadata .heading {
    font-size: 0.8125rem;
    margin: 1.3125rem 0 2.5rem 0;
}

#in-issue .in-issue-sidebar .metadata .heading a {
    text-decoration: none;
    color: var(--common-color1);
}

#in-issue .in-issue-sidebar .metadata .heading .volume-info {
    font-weight: 700;
}

#in-issue .in-issue-sidebar .metadata .heading .date {
    font-family: var(--font-family-sans-serif);
    border-left: 1px solid var(--common-color3);
    margin-left: 0.5rem;
    padding-left: 0.5rem;
}

#in-issue .in-issue-sidebar .metadata .articles .item {
    margin: 1.625rem 0 0 0;
    font-family: var(--font-family-sans-serif);
    font-size: 0.875rem;
    color: var(--common-color1);
}

#in-issue .in-issue-sidebar .metadata .articles .item .item-inner {
    border-bottom: 1px solid var(--common-color3);
    padding-bottom: 0.8125rem;
    margin-bottom: 0.5rem;
}

#in-issue .in-issue-sidebar .metadata .articles .item.last {
    border-bottom: 0;
}

#in-issue .in-issue-sidebar .metadata .articles .item a {
    text-decoration: none;
    color: var(--common-color1);
}

#in-issue .in-issue-sidebar .metadata .articles .item .title {
    display: block;
    font-weight: 700;
}

#in-issue .in-issue-sidebar .metadata .articles .item .info {
    display: block;
}


#in-issue .in-issue-sidebar .metadata .articles .item .more {
    display: block;
    font-size: 0.75rem;
    text-transform: lowercase;
    text-align: right;
}

/* pop articles */
.article-pop-container {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -100%;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 200;
    background-color: var(--color1trans);
    cursor: pointer;
    line-height: normal;
}

.article-pop-container a {
    text-decoration: none;
    color: var(--common-color1);
}

.article-pop-container.pop {
    top: 0;
}

.article.pop .content .item a,
.article.pop .content .item span {
    display: inline;
}

.article[data-article-id] {
    position: relative;
    width: 52.5rem;
    box-sizing: border-box;
    padding: 3.625rem 4.375rem;
    display: none;
    max-width: 60rem;
    max-height: 80%;
    z-index: 250;
    overflow: auto;
    background-color: white;
    cursor: default;
    box-shadow: 0 8px 20px -4px #4f4f4f;
    font-family: var(--font-family-sans-serif);
}

.article-pop-container .article .close-button {
    position: absolute;
    top: 1.5625rem;
    right: 1.5625rem;
}

.article-pop-container .article .close-button button.close {
    width: 1rem;
    height: 1rem;
    padding: 0;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    color: var(--color0);
    fill: var(--color0);
}

.article-pop-container .article .close-button button.close svg {
    width: 100%;
    height: 100%;
    color: var(--color0);
    fill: var(--color0);
}

.article-pop-container .article .heading {
    display: flex;
    align-items: end;
    justify-content: space-between;
    padding: 0 0 1.25rem 0;
    margin-bottom: 1.5625rem;
    border-bottom: 1px solid var(--color0);
    font-size: 0.8125rem;
}

.article-pop-container .article .heading .volume-info {
    font-family: var(--font-family-serif);
    font-weight: 700;
}

.article-pop-container .article .heading .issue-date {
    margin-left: 0.5rem;
    padding-left: 0.5rem;
    border-left: 1px solid var(--common-color3);
}

.article-pop-container .article .in-issue-pop-article {
    display: flex;
    font-size: 0.875rem;
}

.article-pop-container .article .in-issue-pop-article .sidebar {
    box-sizing: border-box;
    width: 12.5rem;
    padding-right: 1.875rem;
    margin-right: 3.125rem;
    border-right: 1px solid var(--color0);
}

.article-pop-container .article .in-issue-pop-article .content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: auto;
    width: min-content;
}

.article-pop-container .article .in-issue-pop-article .content *:first-child{
    margin-top: 0;
}

.article-pop-container .article .in-issue-pop-article span.field-title {
    display: block;
    font-weight: 700;
    margin-top: 1rem;
}

.article-pop-container .article .in-issue-pop-article span.title {
    font-weight: 700;
}

.article-pop-container .article .in-issue-pop-article span.info {
    display: block;
}

.article-pop-container .article[data-article-id].pop {
    display: flex;
    flex-direction: column;
}

/* Viz */
#in-issue .in-issue-page {
    width: calc(100vw - 25rem);
    box-sizing: border-box;
    height: 100%;
    background-color: var(--common-color0);
    overflow: auto;
    padding-bottom: 6.5rem;
}

#in-issue .in-issue-page canvas {
    position: relative;
    display: block;
    top: 0;
    left: 0;
    opacity: 0;
    box-shadow: 0 8px 20px -4px #4f4f4f;
    transition: width 0.5s, height 0.5s, top 0.5s, left 0.5s;
    animation-duration: 1s;
    animation-name: fadein;
}

@keyframes fadein {
    from {
      opacity: 0;
    }

    to {
        opacity: 1;
    }
  }

#in-issue .in-issue-page img {
    visibility: hidden;
    position: absolute;
    top: -1000%;
    left: -1000%;
}

#in-issue .in-issue-page .controls {
    position: absolute;
    font-family: var(--font-family-sans-serif);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 1.5rem;
    width: calc(100vw - 35rem);
    right: 5rem;
    bottom: 2rem;
    z-index: 100;
}

#in-issue .in-issue-page .controls .controls-inner {
    display: flex;
    background-color: rgba(255, 255, 255, 0.6);
    padding: 1rem 0.5rem;
    align-items: center;
}

#in-issue .in-issue-page .controls a {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    margin: 0 0.5rem;
    color: var(--common-color2);
    line-height: 1.5rem;
    text-decoration: none;
    font-weight: 700;
}

#in-issue .in-issue-page .controls span {
    line-height: 2rem;
    color: var(--common-color2);
}

#in-issue .in-issue-page .controls span.zoom {
    width: 4rem;
    text-align: center;
}

#in-issue .in-issue-page .controls a svg {
    width: 100%;
    height: 100%;
}

#in-issue .in-issue-page .controls a.hidden {
    visibility: hidden;
}

#in-issue .in-issue-page .controls .pages {
    display: flex;
    align-items: center;
}

#in-issue .in-issue-page .controls .pages a {
    width: 1.1rem;
    height: 1.1rem;
    line-height: 1.1rem;
}


