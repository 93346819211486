@charset "utf-8";

#footer-logos {
    background-color: var(--common-color1);
    color: var(--common-color3);
    font-family: var(--font-family-sans-serif);
    font-size: 0.8125rem;
    border-top: 1px solid var(--common-color2);
}

#footer-logos > div {
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-evenly; */
    justify-content: space-between;
    align-self: center;
    box-sizing: border-box;
    width: 60rem;
    margin: 0 auto;
    padding: 1.5rem 0.625rem 2.25rem 0.625rem;
}

#footer-logos > div a {
    align-self: center;
}

@media (min-width: 561px) and (max-width: 975px) {
    #footer-logos > div {
        width: 100vw;
        justify-content: space-evenly;
    }

    #footer-logos > div a {
        margin: 1rem;
    }
}

@media (max-width: 560px) {
    #footer-logos > div {
        width: 100vw;
        justify-content: space-evenly;
    }

    #footer-logos > div a {
        margin: 1rem;
    }
}
