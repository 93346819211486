@charset "utf-8";

#magazine-bar {
    background-color: var(--common-color0);
    font-family: var(--font-family-sans-serif);
    font-size: 0.8125rem;
    text-transform: uppercase;
}

#magazine-bar > div {
    display: flex;
    box-sizing: border-box;
    border-bottom: 1px solid var(--color0);
    width: 60rem;
    margin: 0 auto;
    padding: 2.375rem 0 1.875rem 0;
    justify-content: space-between;
}

#magazine-bar .logo {
    display: flex;
}

#magazine-bar .logo a {
    align-self: flex-end;
}

#magazine-bar .magazine-search-nav {
    display: flex;
    flex-direction: column;
    align-self: flex-end;
    align-items: flex-end;
}

#magazine-bar .magazine-search-nav .magazine-search {
    width: 66.666%;
    min-width: 16rem;
}

#magazine-bar .magazine-search-nav .magazine-search form {
    width: 100%;
    text-align: end;
}

#magazine-bar .magazine-search-nav .magazine-search form input {
    box-sizing: border-box;
    width: 100%;
    padding: 0.4rem;
}

#magazine-bar .magazine-search-nav .magazine-search form input::placeholder {
    font-style: italic;
}

#magazine-bar .magazine-search-nav .magazine-nav-wrapper {
    width: 100%;
    overflow: hidden;
}

#magazine-bar .magazine-search-nav .magazine-nav {
    position: relative;
    padding-top: 2rem;
}

#magazine-bar .magazine-search-nav .magazine-nav a {
    font-family: var(--font-family-sans-serif);
    font-size: 0.8125rem;
    text-transform: uppercase;
    text-decoration: none;
    margin-left: 1.5rem;
    font-weight: 600;
    color: var(--common-color2);
}

#magazine-bar .magazine-search-nav .magazine-nav a:first-child {
    margin-left: 0;
}

#magazine-bar .magazine-search-nav .magazine-nav a.selected {
    color: var(--color0);
    font-weight: 700;
}

#magazine-bar .magazine-search-nav .magazine-nav a:hover {
    color: var(--color0);
}

#magazine-bar .magazine-search-nav .right-shadow {
    position: absolute;
    width: 2.5rem;
    height: 100%;
    right: 0;
    height: 3rem;
    background: linear-gradient(90deg, hsla(0, 0%, 100%, 0), #fff);
    pointer-events: none;
    z-index: 90;
}

#magazine-bar .magazine-search-nav .left-shadow {
    position: absolute;
    width: 2.5rem;
    height: 100%;
    left: 0;
    height: 3rem;
    background: linear-gradient(270deg, hsla(0, 0%, 100%, 0), #fff);
    pointer-events: none;
    z-index: 90;
}

@media (min-width: 561px) and (max-width: 975px) {
    #magazine-bar > div {
        width: 100%;
        padding: 1.875rem 0 1.4375rem 0;
        flex-wrap: wrap;
    }

    #magazine-bar .logo {
        width: 100%;
        justify-content: center;
    }

    #magazine-bar .magazine-search-nav {
        width: 100%;
        align-items: center;
    }

    #magazine-bar .magazine-search-nav .magazine-search {
        width: 66.666%;
        max-width: 16rem;
        min-width: unset;
        padding: 1.875rem 0;
    }

    #magazine-bar .magazine-search-nav .magazine-nav {
        width: 100%;
        padding: 0 0 1rem 0;
        overflow: auto;
        text-align: center;
    }

    #magazine-bar .magazine-search-nav .magazine-nav a {
        margin-left: 1.5rem;
        white-space: nowrap;
    }

    #magazine-bar .magazine-search-nav .magazine-nav a:first-child {
        margin-left: 1.5rem;
    }

    #magazine-bar .magazine-search-nav .magazine-nav a:last-child {
        margin-right: 1.5rem;
    }
}

@media (max-width: 560px) {
    #magazine-bar > div {
        width: 100%;
        padding: 1.875rem 0 1.4375rem 0;
        flex-wrap: wrap;
    }

    #magazine-bar .logo {
        width: 100%;
        justify-content: center;
    }

    #magazine-bar .magazine-search-nav {
        width: 100%;
        align-items: center;
    }

    #magazine-bar .magazine-search-nav .magazine-search {
        width: 66.666%;
        min-width: unset;
        padding: 1.875rem 0;
    }

    #magazine-bar .magazine-search-nav .magazine-nav {
        width: 100%;
        padding: 0 0 1rem 0;
        overflow: auto;
        text-align: center;
    }

    #magazine-bar .magazine-search-nav .magazine-nav a {
        margin-left: 1.5rem;
        white-space: nowrap;
    }

    #magazine-bar .magazine-search-nav .magazine-nav a:first-child {
        margin-left: 1.5rem;
    }

    #magazine-bar .magazine-search-nav .magazine-nav a:last-child {
        margin-right: 1.5rem;
    }
}
