@charset "utf-8";

form[name="advanced-search"] {
    font-family: var(--font-family-sans-serif);
    font-size: 0.875rem;
}

form[name="advanced-search"] .magazine-selection-header {
    border-bottom: 1px solid var(--common-color1);
    margin: 0 0 1rem 0;
}

form[name="advanced-search"] .magazine-selection-header .header {
    font-family: var(--font-family-sans-serif);
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.8rem;
}

form[name="advanced-search"] .advanced-search-row {
    margin: 0 0 0.8125rem 0;
}

form[name="advanced-search"] select[name="field-name"] {
    width: 15.75rem;
    background-color: transparent;
    border: 1px solid var(--common-color1);
    height: 1.5625rem;
    line-height: 1.5625rem;
}

form[name="advanced-search"] input[name="field-value"] {
    width: 17.5rem;
    margin: 0 1.875rem;
    background-color: transparent;
    border: 1px solid var(--common-color1);
    height: 1.5625rem;
    line-height: 1.5625rem;
    padding: 0;
}

form[name="advanced-search"] select[name="query-opt"] {
    width: 5.75rem;
    background-color: transparent;
    border: 1px solid var(--common-color1);
    height: 1.5625rem;
    line-height: 1.5625rem;
}

form[name="advanced-search"] .magazine-selection {
    margin: 3.125rem 0;
}

form[name="advanced-search"] .magazine-selection .magazine {
    display: inline-block;
    width: 33.3%;
    padding: 0 0 0.5rem 0;
}

form[name="advanced-search"] .magazine-selection .magazine span {
    margin-left: 0.6rem;
}

form[name="advanced-search"] .submmit {
    text-align: center;
}

form[name="advanced-search"] input[type="text"] {
    box-sizing: border-box;
    padding: 0 .4rem;
}


form[name="advanced-search"] .submmit input {
    font-family: var(--font-family-sans-serif);
    font-size: 0.875rem;
    width: 7.125rem;
    height: 1.875rem;
    padding: 0 1rem;
    background: var(--common-color1);
    color: white;
    border: 1px solid var(--common-color1);
    border-radius: 0;
    cursor: pointer;

}


@media (max-width: 560px) {
    form[name="advanced-search"] .magazine-selection .magazine {
        display: block;
        width: 100%;
        padding: 0 0 0.5rem 0;
    }

    form[name="advanced-search"] select[name="field-name"] {
        width: 17.5rem;
        margin: 1rem 1rem 0 0;
    }

    form[name="advanced-search"] input[name="field-value"] {
        margin: 1rem 1rem 0 0;
    }

    form[name="advanced-search"] select[name="query-opt"] {
        margin: 1rem 1rem 0 0;
    }
}

@media (min-width: 561px) and (max-width: 975px) {
    form[name="advanced-search"] .magazine-selection .magazine {
        display: block;
        width: 100%;
        padding: 0 0 0.5rem 0;
    }

    form[name="advanced-search"] select[name="field-name"] {
        width: 17.5rem;
        margin: 1rem 1rem 0 0;
    }

    form[name="advanced-search"] input[name="field-value"] {
        margin: 1rem 1rem 0 0;
    }

    form[name="advanced-search"] select[name="query-opt"] {
        margin: 1rem 1rem 0 0;
    }
}
