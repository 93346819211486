@charset "utf-8";

:root {
    --common-color0: rgba(255, 255, 255, 1);
    --common-color1: rgba(40, 41, 48, 1);
    --common-color2: rgba(109, 114, 116, 1);
    --common-color3: rgba(171, 175, 179, 1);
}

body[data-color-scheme="br"] {
    --color0: rgba(40, 41, 48, 1);
    --color1: rgba(139, 146, 146, 1);
    --color2: rgba(119, 141, 109, 1);
    --color1trans: rgba(119, 141, 109, 0.8);
}

body[data-color-scheme="pt"] {
    --color0: rgba(40, 41, 48, 1);
    --color1: rgba(139, 146, 146, 1);
    --color2: rgba(233, 233, 235, 1);
    --color1trans: rgba(119, 141, 109, 0.8);
}

body[data-color-scheme="revista-de-antropofagia"] {
    --color0: rgba(76, 115, 154, 1);
    --color1: rgba(248, 250, 252, 1);
    --color1trans: rgba(248, 250, 252, 0.8);
}

body[data-color-scheme="a-revista"] {
    --color0: rgba(136, 45, 26, 1);
    --color1: rgba(253, 242, 232, 1);
    --color1trans: rgba(253, 242, 232, 0.8);
}

body[data-color-scheme="estetica"] {
    --color0: rgba(55, 123, 113, 1);
    --color1: rgba(236, 246, 240, 1);
    --color1trans: rgba(236, 246, 240, 0.8);
}

body[data-color-scheme="klaxon"] {
    --color0: rgba(215, 55, 60, 1);
    --color1: rgba(241, 236, 228, 1);
    --color1trans: rgba(241, 236, 228, 0.8);
}

body[data-color-scheme="terra-roxa-e-outras-terras"] {
    --color0: rgba(100, 74, 108, 1);
    --color1: rgba(249, 246, 249, 1);
    --color1trans: rgba(249, 246, 249, 0.8);
}

body[data-color-scheme="verde"] {
    --color0: rgba(91, 127, 46, 1);
    --color1: rgba(238, 244, 230, 1);
    --color1trans: rgba(238, 244, 230, 0.8);
}

body[data-color-scheme="a-construcao-moderna"] {
    --color0: rgba(194, 126, 59, 1);
    --color1: rgba(244, 232, 208, 1);
    --color1trans: rgba(244, 232, 208, 0.8);
}

body[data-color-scheme="sociedade-futura"] {
    --color0: rgba(161, 99, 122, 1);
    --color1: rgba(252, 247, 255, 1);
    --color1trans: rgba(252, 247, 255, 0.8);
}
body[data-color-scheme="nova-silva"] {
    --color0: rgba(116, 120, 78, 1);
    --color1: rgba(218, 219, 189, 1);
    --color1trans: rgba(218, 219, 189, 0.8);
}
body[data-color-scheme="a-sementeira"] {
    --color0: rgba(179, 38, 31, 1);
    --color1: rgba(239, 238, 238, 1);
    --color1trans: rgba(239, 238, 238, 0.8);
}
body[data-color-scheme="a-aguia"] {
    --color0: rgba(127, 116, 117, 1);
    --color1: rgba(239, 232, 223, 1);
    --color1trans: rgba(239, 232, 223, 0.8);
}
body[data-color-scheme="a-mulher-portuguesa"] {
    --color0: rgba(134, 93, 144, 1);
    --color1: rgba(215, 207, 218, 1);
    --color1trans: rgba(215, 207, 218, 0.8);
}
body[data-color-scheme="a-vida-portuguesa"] {
    --color0: rgba(119, 119, 108, 1);
    --color1: rgba(226, 223, 209, 1);
    --color1trans: rgba(226, 223, 209, 0.8);
}
body[data-color-scheme="orpheu"] {
    --color0: rgba(82, 125, 123, 1);
    --color1: rgba(221, 229, 223, 1);
    --color1trans: rgba(221, 229, 223, 0.8);
}
body[data-color-scheme="contemporanea"] {
    --color0: rgba(86, 121, 87, 1);
    --color1: rgba(236, 223, 219, 1);
    --color1trans: rgba(236, 223, 219, 0.8);
}
body[data-color-scheme="eh-real!"] {
    --color0: rgba(122, 118, 103, 1);
    --color1: rgba(223, 221, 201, 1);
    --color1trans: rgba(223, 221, 201, 0.8);
}
body[data-color-scheme="atlantida"] {
    --color0: rgba(92, 125, 111, 1);
    --color1: rgba(210, 216, 201, 1);
    --color1trans: rgba(210, 216, 201, 0.8);
}
body[data-color-scheme="germinal"] {
    --color0: rgba(166, 76, 69, 1);
    --color1: rgba(222, 222, 221, 1);
    --color1trans: rgba(222, 222, 221, 0.8);
}
body[data-color-scheme="exilio"] {
    --color0: rgba(140, 63, 67, 1);
    --color1: rgba(239, 234, 227, 1);
    --color1trans: rgba(239, 234, 227, 0.8);
}
body[data-color-scheme="centauro"] {
    --color0: rgba(110, 119, 108, 1);
    --color1: rgba(226, 223, 209, 1);
    --color1trans: rgba(226, 223, 209, 0.8);
}
body[data-color-scheme="sphinx"] {
    --color0: rgba(127, 100, 127, 1);
    --color1: rgba(225, 220, 224, 1);
    --color1trans: rgba(225, 220, 224, 0.8);
}
body[data-color-scheme="a-tradicao"] {
    --color0: rgba(104, 120, 130, 1);
    --color1: rgba(234, 236, 238, 1);
    --color1trans: rgba(234, 236, 238, 0.8);
}
body[data-color-scheme="portugal-futurista"] {
    --color0: rgba(170, 85, 75, 1);
    --color1: rgba(228, 227, 207, 1);
    --color1trans: rgba(228, 227, 207, 0.8);
}
body[data-color-scheme="seara-nova"] {
    --color0: rgba(126, 113, 117, 1);
    --color1: rgba(229, 227, 224, 1);
    --color1trans: rgba(229, 227, 224, 0.8);
}
body[data-color-scheme="suplemento-de-a-batalha"] {
    --color0: rgba(152, 36, 30, 1);
    --color1: rgba(194, 193, 193, 1);
    --color1trans: rgba(194, 193, 193, 0.8);
}
body[data-color-scheme="athena"] {
    --color0: rgba(111, 120, 111, 1);
    --color1: rgba(230, 223, 211, 1);
    --color1trans: rgba(230, 223, 211, 0.8);
}
body[data-color-scheme="renovacao"] {
    --color0: rgba(138, 37, 43, 1);
    --color1: rgba(208, 207, 207, 1);
    --color1trans: rgba(208, 207, 207, 0.8);
}
body[data-color-scheme="principio"] {
    --color0: rgba(126, 114, 109, 1);
    --color1: rgba(235, 224, 215, 1);
    --color1trans: rgba(235, 224, 215, 0.8);
}
body[data-color-scheme="cadernos-da-juventude"] {
    --color0: rgba(135, 77, 71, 1);
    --color1: rgba(243, 226, 206, 1);
    --color1trans: rgba(243, 226, 206, 0.8);
}
body[data-color-scheme="sol-nascente"] {
    --color0: rgba(173, 48, 43, 1);
    --color1: rgba(239, 227, 208, 1);
    --color1trans: rgba(239, 227, 208, 0.8);
}
body[data-color-scheme="altitude"] {
    --color0: rgba(192, 80, 50, 1);
    --color1: rgba(255, 245, 225, 1);
    --color1trans: rgba(255, 245, 225, 0.8);
}
body[data-color-scheme="ler"] {
    --color0: rgba(150, 42, 63, 1);
    --color1: rgba(244, 233, 215, 1);
    --color1trans: rgba(244, 233, 215, 0.8);
}
body[data-color-scheme="kwy"] {
    --color0: rgba(130, 116, 98, 1);
    --color1: rgba(213, 199, 183, 1);
    --color1trans: rgba(213, 199, 183, 0.8);
}
body[data-color-scheme="o-tempo-e-o-modo"] {
    --color0: rgba(97, 119, 122, 1);
    --color1: rgba(222, 227, 223, 1);
    --color1trans: rgba(222, 227, 223, 0.8);
}
body[data-color-scheme="piramide"] {
    --color0: rgba(135, 54, 69, 1);
    --color1: rgba(227, 231, 224, 1);
    --color1trans: rgba(227, 231, 224, 0.8);
}
