@charset "utf-8";

#top-bar {
    width: 100%;
    background-color: var(--common-color0);
}

#top-bar > div {
    display: flex;
    box-sizing: border-box;
    width: 60rem;
    margin: 0 auto;
    padding: 0.625rem 0 0.625rem 0;
    justify-content: space-between;
}

#top-bar .logos, #top-bar .logos-responsive {
    justify-content: flex-start;
    flex-grow: 1;
    align-items: center;
}

#top-bar .logos-responsive {
    display: none;
}

#top-bar .logos img, #top-bar .logos-responsive img {
    margin-right: 1rem;
}

#top-bar .lang {
    font-family: var(--font-family-sans-serif);
    font-size: 0.8125rem;
    font-weight: 700;
    align-self: center;
    justify-content: flex-end;
}

#top-bar .lang a {
    text-decoration: none;
    color: var(--common-color3);
    padding: 0 0 0 0.3rem;
}

#top-bar .lang a:first-child {
    padding: 0 0.3rem 0 0;
}

#top-bar .lang a.selected,
#top-bar .lang a:hover {
    color: var(--common-color1);
}

@media (min-width: 561px) and (max-width: 975px) {
    #top-bar > div {
        width: 100%;
        padding: 0.625rem;
    }

    #top-bar .logos {
        display: none;
    }

    #top-bar .logos-responsive {
        display: flex;
    }
}

@media (max-width: 560px) {
    #top-bar > div {
        width: 100%;
        padding: 0.625rem;
    }

    #top-bar .logos {
        display: none;
    }

    #top-bar .logos-responsive {
        display: flex;
    }
}
