@charset "utf-8";

.carousel {
    width: 100%;
    text-align: center;
    padding: 0;
}

.carousel[data-homepage="true"] {
    padding: 2.35rem 0 3rem 0;
}

body[data-color-scheme="pt"] .carousel[data-homepage="true"] span.info {
    color: var(--common-color1);
}

.carousel[data-magazine][data-active-color="false"][data-site="pt"] .carousel-item .title,
.carousel[data-magazine][data-active-color="false"][data-site="pt"] .carousel-item .info {
    color: var(--common-color1);
}

.carousel[data-homepage="true"] h2 {
    margin: 0 0 2.5rem 0;
}

.carousel h2 {
    font-size: 1.25rem;
    margin: 0 auto;
}

.carousel .orderSearchForm {
    margin: 0 0 2.5rem 0;
}

.carousel .orderSearchForm select, .carousel .orderSearchForm input {
    font-family: var(--font-family-sans-serif);
    font-size: 0.8125rem;
    width: 16.75rem;
    height: 1.5625rem;
    line-height: 1.5625rem;
    border: 0;
    background-color: var(--common-color0);
}

.carousel .orderSearchForm input[type="text"]::placeholder {
    font-style: italic;
}

.carousel .orderSearchForm select {
    height: calc(1.5625rem + 2px);
    margin-right: 1rem;
}

.carousel .carousel-item {
    position: relative;
    display: inline-block;
    margin: 0 1.5rem;
}

.carousel .flickity-slider {
    padding: 0 1.5rem;
}

.carousel .flickity-page-dots {
    bottom: -22px;
}

.carousel .flickity-page-dots .dot {
    height: 4px;
    width: 40px;
    margin: 0;
    border-radius: 0;
}

.carousel .flickity-page-dots .dot:only-child {
    display: none;
}

.carousel .carousel-inner {
    white-space: nowrap;
}

.carousel .carousel-inner .carousel-item:first-child {
    padding-left: 1.5rem;
}
.carousel .carousel-inner .carousel-item:last-child {
    padding-right: 1.5rem;
}

.carousel .carousel-item img {
    box-shadow: 0px 8px 20px -4px rgba(79, 79, 79, 1);
    max-height: 12.8125rem;
}

.carousel[data-shadows="false"] .carousel-item img {
    box-shadow: 0 0 0 0;
}

.carousel .carousel-item .soon {
    position: absolute;
    display: block;
    background-color: var(--common-color2);
    color: var(--common-color0);
    top: 8rem;
    right: 0;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 0.8rem;
    line-height: 0.8;
    font-family: var(--font-family-sans-serif);
    padding: 0.4rem 0.2rem;
}

.carousel .carousel-inner .carousel-item:last-child .soon {
    right: 1.5rem;
}

.carousel .carousel-item .title,
.carousel .carousel-item .info {
    display: block;
    line-height: 1.2rem;
    max-width: 10rem;
    margin: 0 auto;
    white-space: break-spaces;
}

.carousel .carousel-item .title {
    font-family: var(--font-family-serif);
    font-size: 0.875rem;
    font-weight: 700;
    max-width: 8.5rem;
    margin: 1.1rem auto 0 auto;
}

.carousel[data-magazine] .title {
    color: var(--common-color0);
}

.carousel .carousel-item .info {
    font-family: var(--font-family-sans-serif);
    font-size: 0.8125rem;
    color: var(--common-color0);
}

.carousel .flickity-button[disabled] {
    display: none;
}

.carousel[data-active-color="true"] {
    background-color: var(--color0);
}

body[data-color-scheme="br"] .carousel[data-active-color="true"] {
    background-color: var(--color2);
}

body[data-color-scheme="pt"] .carousel[data-active-color="true"] {
    background-color: var(--color2);
}

.carousel[data-active-color="false"] .carousel-item .info {
    color: var(--common-color1);
}

.carousel[data-magazine][data-active-color="false"] .carousel-item .info {
    color: var(--common-color0);
}

.carousel[data-magazine] {
    padding: 4.625rem 0 4.125rem 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin-top: -1px;
}

.carousel[data-magazine="estetica"] {
    background-image: url("/assets/media/images/carousel-backgrounds/estetica@3x.jpg");
}

.carousel[data-magazine="terra-roxa-e-outras-terras"] {
    background-image: url("/assets/media/images/carousel-backgrounds/terra-roxa-e-outras-terras@3x.jpg");
}

.carousel[data-magazine="verde"] {
    background-image: url("/assets/media/images/carousel-backgrounds/verde@3x.jpg");
}

.carousel[data-magazine="klaxon"] {
    background-image: url("/assets/media/images/carousel-backgrounds/klaxon@3x.jpg");
}

.carousel[data-magazine="a-revista"] {
    background-image: url("/assets/media/images/carousel-backgrounds/a-revista@3x.jpg");
}

.carousel[data-magazine="revista-de-antropofagia"] {
    background-image: url("/assets/media/images/carousel-backgrounds/revista-de-antropofagia@3x.jpg");
}

.carousel[data-magazine="a-aguia"] {
    background-image: url("/assets/media/images/carousel-backgrounds/a-aguia@3x.jpg");
}

.carousel[data-magazine="a-construcao-moderna"] {
    background-image: url("/assets/media/images/carousel-backgrounds/a-construcao-moderna@3x.jpg");
}

.carousel[data-magazine="sociedade-futura"] {
    background-image: url("/assets/media/images/carousel-backgrounds/sociedade-futura@3x.jpg");
}

.carousel[data-magazine="nova-silva"] {
    background-image: url("/assets/media/images/carousel-backgrounds/nova-silva@3x.jpg");
}

.carousel[data-magazine="a-sementeira"] {
    background-image: url("/assets/media/images/carousel-backgrounds/a-sementeira@3x.jpg");
}

.carousel[data-magazine="a-mulher-portuguesa"] {
    background-image: url("/assets/media/images/carousel-backgrounds/a-mulher-portuguesa@3x.jpg");
}

.carousel[data-magazine="a-vida-portuguesa"] {
    background-image: url("/assets/media/images/carousel-backgrounds/a-vida-portuguesa@3x.jpg");
}

.carousel[data-magazine="orpheu"] {
    background-image: url("/assets/media/images/carousel-backgrounds/orpheu@3x.jpg");
}

.carousel[data-magazine="contemporanea"] {
    background-image: url("/assets/media/images/carousel-backgrounds/contemporanea@3x.jpg");
}

.carousel[data-magazine="eh-real!"] {
    background-image: url("/assets/media/images/carousel-backgrounds/eh-real!@3x.jpg");
}

.carousel[data-magazine="atlantida"] {
    background-image: url("/assets/media/images/carousel-backgrounds/atlantida@3x.jpg");
}

.carousel[data-magazine="germinal"] {
    background-image: url("/assets/media/images/carousel-backgrounds/germinal@3x.jpg");
}

.carousel[data-magazine="exilio"] {
    background-image: url("/assets/media/images/carousel-backgrounds/exilio@3x.jpg");
}

.carousel[data-magazine="centauro"] {
    background-image: url("/assets/media/images/carousel-backgrounds/centauro@3x.jpg");
}

.carousel[data-magazine="sphinx"] {
    background-image: url("/assets/media/images/carousel-backgrounds/sphinx@3x.jpg");
}

.carousel[data-magazine="a-tradicao"] {
    background-image: url("/assets/media/images/carousel-backgrounds/a-tradicao@3x.jpg");
}

.carousel[data-magazine="portugal-futurista"] {
    background-image: url("/assets/media/images/carousel-backgrounds/portugal-futurista@3x.jpg");
}

.carousel[data-magazine="seara-nova"] {
    background-image: url("/assets/media/images/carousel-backgrounds/seara-nova@3x.jpg");
}

.carousel[data-magazine="suplemento-de-a-batalha"] {
    background-image: url("/assets/media/images/carousel-backgrounds/suplemento-de-a-batalha@3x.jpg");
}

.carousel[data-magazine="athena"] {
    background-image: url("/assets/media/images/carousel-backgrounds/athena@3x.jpg");
}

.carousel[data-magazine="renovacao"] {
    background-image: url("/assets/media/images/carousel-backgrounds/renovacao@3x.jpg");
}

.carousel[data-magazine="principio"] {
    background-image: url("/assets/media/images/carousel-backgrounds/principio@3x.jpg");
}

.carousel[data-magazine="cadernos-da-juventude"] {
    background-image: url("/assets/media/images/carousel-backgrounds/cadernos-da-juventude@3x.jpg");
}

.carousel[data-magazine="sol-nascente"] {
    background-image: url("/assets/media/images/carousel-backgrounds/sol-nascente@3x.jpg");
}

.carousel[data-magazine="altitude"] {
    background-image: url("/assets/media/images/carousel-backgrounds/altitude@3x.jpg");
}

.carousel[data-magazine="ler"] {
    background-image: url("/assets/media/images/carousel-backgrounds/ler@3x.jpg");
}

.carousel[data-magazine="kwy"] {
    background-image: url("/assets/media/images/carousel-backgrounds/kwy@3x.jpg");
}

.carousel[data-magazine="o-tempo-e-o-modo"] {
    background-image: url("/assets/media/images/carousel-backgrounds/o-tempo-e-o-modo@3x.jpg");
}

.carousel[data-magazine="piramide"] {
    background-image: url("/assets/media/images/carousel-backgrounds/piramide@3x.jpg");
}

@media (min-width: 561px) and (max-width: 975px) {
    .carousel .flickity-button {
        display: none;
    }

    .carousel .flickity-page-dots {
        display: none;
    }
}

@media (max-width: 560px) {
    .carousel .flickity-button {
        display: none;
    }

    .carousel .flickity-page-dots {
        display: none;
    }
}
