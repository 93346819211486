@charset "utf-8";

:root {
    /* stylelint-disable-next-line unit-whitelist */
    --font-size: 16px;
    --font-family-serif: "Noto Serif", serif;
    --font-family-sans-serif: "Open Sans", sans-serif;
    --font-family-monospace: monospace;
}

h1, h2, h3, h4, h5, h6 {
    font-family: var(--font-family-serif);
}

h1 {
    font-size: 1.4375rem;
    font-weight: 400;
}

p {
    font-family: var(--font-family-sans-serif);
    font-size: var(--font-size);
    line-height: normal;
}
