@charset "utf-8";

#highlights {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 4.375rem 0;
    background-color: var(--common-color0);
}

#highlights .highlight,
#highlights a {
    text-align: center;
    width: 12.625rem;
    margin: 0 1.5rem;
}

#highlights .highlight .illustration {
    width: 10rem;
    height: 10rem;
    margin: 0 auto;
    margin-bottom: 1.4rem;
    border-radius: 5rem;
    background-color: red;
}

#highlights .highlight span {
    display: block;
    text-align: center;
}

#highlights a {
    text-decoration: none;
    color: var(--color0);
}

#highlights .highlight span.highlight-title {
    display: block;
    max-width: 10rem;
    font-family: var(--font-family-serif);
    text-decoration: none;
    font-size: 1.1rem;
    line-height: 1.5rem;
    font-weight: 700;
    margin: 1.4rem auto 0.4rem auto;
}

#highlights .highlight span.highlight-subtitle {
    font-size: 0.875rem;
}

@media (min-width: 561px) and (max-width: 975px) {
    #highlights {
        padding: 0 0 3.125rem 0;
    }

    #highlights .highlight,
    #highlights a {
        margin: 3.125rem 0 0 0;
    }
}

@media (max-width: 560px) {
    #highlights {
        padding: 3.125rem 0;
    }

    #highlights .highlight,
    #highlights a {
        width: 100%;
        margin: 3.125rem 0 0 0;
    }

    #highlights .highlight:first-child,
    #highlights a:first-child {
        margin: 0;
    }
}
