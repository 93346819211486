@charset "utf-8";

.main-content > ._404-container {
    padding: 4.6875rem 0;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.main-content > ._404-container ._404_title {
    font-family: var(--font-family-sans-serif);
    font-size: 4.375rem;
    font-weight: 700;
    text-transform: uppercase;
    display: block;
    margin: 1.5625rem 0;
}

.main-content > ._404-container ._404_info {
    font-family: var(--font-family-serif);
    font-size: 1.5rem;
    display: block;
    margin-bottom: 1.875rem;
}

.main-content > ._404-container .back_link,
.main-content > ._404-container .back_link:visited,
.main-content > ._404-container .back_link:hover {
    display: block;
    margin: 0 auto;
    width: 8.75rem;
    height: 1.875rem;
    line-height: 1.875rem;
    background-color: var(--common-color1);
    color: var(--common-color0);
    font-size: 0.9375rem;
    font-family: var(--font-family-sans-serif);
    font-weight: 700;
    text-transform: uppercase;
    text-decoration: none;
}
