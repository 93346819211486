@charset "utf-8";

.main-content {
    width: 100%;
    background-color: var(--common-color0);
    text-align: center;
}

.main-content a {
    color: var(--color0);
}

.main-content > div {
    display: flex;
    width: 60rem;
    margin: 0 auto;
    /* border-top: 1px solid var(--color0); */
    padding: 3.5rem 0;
    text-align: left;
}

.main-content.full-width > div {
    width: 100%;
}

body[data-color-scheme="br"] .main-content > div {
    border-top: 0;
}

.main-content .section-menu {
    box-sizing: border-box;
    width: 12.25rem;
    border-right: 1px solid var(--color0);
}

.main-content .section-menu hr {
    margin: 2rem 0;
    border: 0;
    border-top: 1px solid var(--color0);
}

.main-content .section-menu a {
    display: block;
    font-family: var(--font-family-sans-serif);
    font-size: 0.875rem;
    text-decoration: none;
    color: var(--common-color2);
    margin: 0 0 1rem 0;
}

.main-content .section-menu a.selected {
    font-weight: 700;
    color: var(--color0);
}

.main-content .section-menu a:hover {
    color: var(--color0);
}

.main-content .section-content {
    display: flex;
    box-sizing: border-box;
    width: 47.75rem;
    padding: 0 0 0 3.125rem;
    flex-grow: 1;
}

.main-content .section-content[data-magasin-section="true"].with-carousel {
    padding: 0;
}

.main-content .section-content hr {
    border: 0;
    border-top: 1px solid var(--common-color3);
    width: 100%;
}

.main-content .section-content .section-content-inner {
    padding: 0;
    margin-left: 1rem;
    flex: auto;
}

.main-content .section-content .section-content-illustrations + .section-content-inner {
    padding-top: 3rem;
    margin-left: 4.4375rem;
}

.main-content .section-content .item .info {
    display: block;
    margin: 0.3rem 0;
}

.main-content .section-content .section-content-inner h1,
.main-content .section-content .section-content-inner h2,
.main-content .section-content .section-content-inner h3,
.main-content .section-content .section-content-inner h4,
.main-content .section-content .section-content-inner h5 {
    font-family: var(--font-family-serif);
    color: var(--color0);
    font-weight: 400;
}

.main-content .section-content[data-magasin-section="true"] h1,
.main-content .section-content[data-movements-section="true"] h1 {
    margin: 0;
}

.main-content .section-content[data-magasin-section="true"] h2 {
    font-family: var(--font-family-sans-serif);
    font-size: 0.9375rem;
    text-transform: uppercase;
    margin: 2.5rem 0;
}

.main-content .section-content[data-magasin-section="true"] .extra + h2 {
    margin-top: 1.625rem;
}

.main-content .section-content[data-magasin-section="true"] .extra-split + h2 {
    margin-top: 0;
}

.main-content .section-content[data-magasin-section="true"],
.main-content .section-content[data-movements-section="true"] {
    flex-direction: column;
    padding: 0 0 0 4.125rem;
}

.main-content .section-content[data-indexes-section="true"] {
    flex-wrap: wrap;
    align-content: flex-start;
    padding: 0 0 0 4.125rem;
}

.main-content .section-content[data-index-section="true"] {
    flex-direction: column;
    flex-wrap: wrap;
    padding: 0 0 0 4.125rem;
}

.main-content .section-content[data-search-section="true"] {
    padding: 0 0 0 4.125rem;
}

.main-content .section-content[data-index-section="true"] .heading {
    padding-bottom: 1.625rem;
    border-bottom: 1px solid var(--color0);
}

.main-content .section-content[data-search-section="true"] .heading {
    padding-bottom: 1.625rem;
}

.main-content .section-content[data-index-section="true"] .heading h1,
.main-content .section-content[data-search-section="true"] .heading h1 {
    font-size: 1.125rem;
    margin: 0;
}

.main-content .section-content[data-index-section="true"] .heading h1 {
    font-weight: 700;
}

.main-content .section-content[data-index-section="true"] .heading .sub-info {
    margin-top: 1.625rem;
}

.main-content .section-content[data-index-section="true"] .tabs,
.main-content .section-content[data-search-section="true"] .tabs {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    font-family: var(--font-family-sans-serif);
    color: var(--common-color2);
    margin: 0.8125rem 0 3.375rem 0;
    font-size: 0.8125rem;
}

.main-content .section-content[data-issue-section="true"] {
    flex-direction: column;
    padding: 0 0 0 4.125rem;
}

.main-content .section-content[data-issue-section="true"] .heading {
    font-size: 1.125rem;
    margin-bottom: 3.375rem;
}

.main-content .section-content[data-issue-section="true"] .heading .volume-info {
    font-weight: 700;
}

.main-content .section-content[data-issue-section="true"] .heading .date {
    font-family: var(--font-family-sans-serif);
    border-left: 1px solid var(--color0);
    margin-left: 0.5rem;
    padding-left: 0.5rem;
}

.main-content .section-content[data-issue-section="true"] .issue-index {
    display: flex;
    flex-direction: column;
}

.main-content .section-menu .issue-cover {
    display: inline-block;
    text-align: center;
}

.main-content .section-menu .issue-cover img {
    box-shadow: 0 8px 20px -4px #4f4f4f;
    max-width: 10rem;
}

.main-content .section-menu .issue-cover a {
    text-transform: lowercase;
    font-weight: 700;
    color: var(--color0);
}

.main-content .section-menu .issue-cover span {
    margin-top: 1rem;
    display: inline-block;
}

.main-content .section-content[data-issue-section="true"] .issue-index .item {
    font-family: var(--font-family-sans-serif);
    font-size: 0.9375rem;
    border-bottom: 1px solid var(--common-color3);
    padding-bottom: 0.8125rem;
}

.main-content .section-content[data-issue-section="true"] .issue-index .item.last {
    border-bottom: 0;
}

.main-content .section-content[data-issue-section="true"] .issue-index .item a {
    color: var(--common-color1);
    text-decoration: none;
}

.main-content
    .section-content[data-issue-section="true"]
    .issue-index
    .item
    .info.physical-publication
    a {
    font-family: var(--font-family-serif);
    font-weight: 700;
    color: var(--color0);
}

.main-content .section-content[data-issue-section="true"] .issue-index .item .title {
    font-weight: 700;
}

.main-content .section-content[data-index-section="true"] .tabs .tab,
.main-content .section-content[data-search-section="true"] .tabs .tab {
    margin-left: 1rem;
    margin-top: .8125rem
}

.main-content .section-content[data-index-section="true"] .tabs .tab.selected,
.main-content .section-content[data-search-section="true"] .tabs .tab.selected {
    font-weight: 700;
    color: var(--color0);
}

.main-content .section-content[data-index-section="true"] .tabs .tab a,
.main-content .section-content[data-search-section="true"] .tabs .tab a {
    text-decoration: none;
    color: var(--common-color2);
}

.main-content .section-content[data-index-section="true"] .tabs .tab:hover a,
.main-content .section-content[data-search-section="true"] .tabs .tab:hover a {
    color: var(--color0);
}

.main-content .section-content[data-index-section="true"] .tabs .tab.selected a,
.main-content .section-content[data-search-section="true"] .tabs .tab.selected a {
    color: var(--color0);
}

.main-content .section-content[data-index-section="true"] .tabs .tab .counter,
.main-content .section-content[data-search-section="true"] .tabs .tab .counter {
    border-left: 1px solid var(--common-color2);
    margin-left: 0.3rem;
    padding-left: 0.3rem;
}

.main-content .section-content[data-index-section="true"] .tabs .tab.selected .counter,
.main-content .section-content[data-search-section="true"] .tabs .tab.selected .counter {
    border-left: 2px solid var(--color0);
    margin-left: 0.3rem;
    padding-left: 0.3rem;
}

.main-content .section-content[data-index-section="true"] span.title {
    display: block;
    font-family: var(--font-family-sans-serif);
    font-weight: 700;
}

.main-content .section-content[data-index-section="true"] span.title a {
    color: var(--common-color1);
}
.main-content .section-content[data-index-section="true"] span.info {
    font-family: var(--font-family-sans-serif);
    display: block;
}

.main-content .section-content[data-index-section="true"] span.info a {
    color: var(--common-color1);
    text-decoration: none;
}

.main-content .section-content .item {
    margin: 1.625rem 0 0.8125rem 0;
}

.main-content .section-content[data-indexes-section="true"] .indexes-list,
.main-content .section-content[data-indexes-section="true"] .item,
.main-content .section-content[data-index-section="true"] .item,
.main-content .section-content[data-search-section="true"] .item {
    width: 100%;
    font-family: var(--font-family-sans-serif);
    font-size: 0.9375rem;
}

.main-content .section-content[data-indexes-section="true"] .item a,
.main-content .section-content[data-search-section="true"] .item a {
    color: var(--common-color1);
}

.main-content .section-content[data-indexes-section="true"] .item .title,
.main-content .section-content[data-search-section="true"] .item .title {
    display: block;
    font-weight: 700;
}

.main-content .section-content[data-indexes-section="true"] .item .info,
.main-content .section-content[data-search-section="true"] .item .info {
    display: block;
}

.main-content .section-content[data-indexes-section="true"] .pagination {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 5rem;
    font-family: var(--font-family-sans-serif);
    line-height: 1.5625rem;
}

.main-content .section-content[data-indexes-section="true"] .pagination.top {
    margin: 0 0 1rem 0;
}

.main-content .section-content[data-indexes-section="true"] .pagination a {
    text-decoration: none;
    color: var(--color0);
    width: 1.5625rem;
    height: 1.5625rem;
    margin: 0 1rem;
}

.main-content .section-content[data-indexes-section="true"] .pagination a svg {
    width: 100%;
    height: 100%;
}

.main-content .section-content .item:first-child {
    margin-top: 0;
}

.main-content .section-content .item:last-child {
    margin-bottom: 0;
}

.main-content .section-content .section-content-illustrations {
    width: 9.375rem;
    margin: 0;
}

.main-content .section-content .section-content-illustrations .illustration {
    width: 9.375rem;
    margin: 0 0 2.5rem 0;
}

.main-content .section-content .section-content-illustrations .illustration img {
    width: 9.375rem;
}

.main-content .section-content .section-content-illustrations .illustration img.shadow {
    box-shadow: 0 8px 20px -4px #000;
}

.main-content .section-content .section-content-illustrations .illustration img + span {
    display: block;
    font-size: 0.9rem;
    color: var(--color0);
    text-align: center;
    margin-top: 1.2rem;
}

.main-content .section-content .section-content-inner p {
    font-size: 0.9375rem;
}

.main-content .section-content[data-foreword-section] .section-content-inner h2 {
    margin-top: 2rem;
}

.main-content .section-content[data-foreword-section="team"] .section-content-inner h2 {
    font-family: var(--font-family-sans-serif);
    font-size: 0.9375rem;
    font-weight: 700;
    margin: 1.5rem 0 0 0;
}

.main-content .section-content[data-foreword-section="team"] .section-content-inner h2 + p {
    margin-top: 0;
}

.main-content .section-content[data-foreword-section] h1 {
    margin-top: 0;
    margin-bottom: 2.5rem;
}

.main-content .section-content[data-foreword-section] h2 {
    font-family: var(--font-family-sans-serif);
    font-weight: 700;
    font-size: 0.9375rem;
}

.main-content .section-content[data-foreword-section] .footnotes {
    font-size: 0.8125rem;
}

.main-content .section-content[data-foreword-section] ol li::marker {
    font-size: 0.9375rem;
    font-family: var(--font-family-sans-serif);
}

.main-content .section-content[data-foreword-section] .footnotes ol li p {
    font-size: 0.8125rem;
}

.main-content .section-content .indexes-letters {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 5rem;
}

.main-content .section-content .indexes-letters .letter {
    display: block;
    width: 1.5625rem;
    height: 1.5625rem;
    font-family: var(--font-family-sans-serif);
    font-size: 0.875rem;
    text-transform: uppercase;
    line-height: 1.5625rem;
    text-align: center;
}

.main-content .section-content .indexes-letters .letter a {
    display: block;
    width: 100%;
    height: 100%;
    font-weight: 700;
    text-decoration: none;
    transition: background-color 0.3s linear, color 0.3s linear;
}

.main-content .section-content .indexes-letters .letter a:hover {
    background-color: var(--color0);
    color: var(--common-color0);
    font-weight: 700;
}

.main-content .section-content .indexes-letters .letter.selected {
    background-color: var(--color0);
    color: var(--common-color0);
    font-weight: 700;
}

.main-content .section-content .extra-split {
    display: block;
    background-color: var(--color0);
    width: 2.25rem;
    height: 0.25rem;
    margin: 0.4375rem 0 2.5rem;
}

.main-content .section-content .extra {
    display: flex;
    align-items: flex-end;
    position: relative;
    border-bottom: 1px solid var(--common-color3);
    padding-bottom: 1.625rem;
    margin-bottom: 1.625rem;
}

.main-content .section-content .extra .full-text {
    position: absolute;
    top: 0;
    right: 0;
    height: 1.375rem;
}

.main-content .section-content .extra .full-text svg {
    height: 100%;
    color: var(--color0);
}

.main-content .section-content .extra a {
    color: var(--common-color1);
    text-decoration: none;
}

/* .main-content .section-content .extra.last {
    border-bottom: 0;
} */

.main-content .section-content .extra .extra-thumb {
    min-width: 8.375rem;
}

.main-content .section-content .extra .extra-thumb .extra-default-thumb {
    width: 8.375rem;
    height: 13.5rem;
    background-color: #ccc;
}

.main-content .section-content .extra .extra-thumb img {
    max-width: 8.375rem;
    box-shadow: 0 8px 20px -4px #4f4f4f;
}

.main-content .section-content .extra .extra-metadata {
    font-family: var(--font-family-sans-serif);
    font-size: 0.9375rem;
    margin-left: 4.4375rem;
}

.main-content .section-content .extra.full-text .extra-metadata {
    margin-top: 4.4375rem;
}

.main-content .section-content .extra .extra-metadata span {
    display: block;
}

.main-content .section-content .extra .extra-metadata span.title {
    font-weight: 700;
}

.main-content .section-content .extra-metadata audio {
    margin-top: 1rem;
}

.main-content .section-content[data-search-section="true"] {
    flex-direction: column;
}

.main-content .section-content[data-search-section="true"] .heading {
    padding-bottom: 1.625rem;
    border-bottom: 1px solid var(--color0);
}

.main-content .section-content[data-search-section="true"] span.search-for {
    font-weight: 700;
}

.main-content .section-content[data-search-section="true"] .search-form-container {
    margin: 2rem 0;
    font-size: 0.9375rem;
}

.main-content .section-content[data-search-section="true"] .search-form-container form {
    display: flex;
}

.main-content
    .section-content[data-search-section="true"]
    .search-form-container
    input[type="text"] {
    box-sizing: border-box;
    padding: 0.4rem;
    width: 66%;
    max-width: 20rem;
    font-family: var(--font-family-sans-serif);
}

.main-content
    .section-content[data-search-section="true"]
    .search-form-container
    input[type="text"]::placeholder {
    font-style: italic;
}

.main-content .section-content[data-in-numbers-section="true"] {
    flex-direction: column;
    padding: 0 0 0 4.125rem;
}

.main-content .section-content[data-in-numbers-section="true"] h1 {
    margin-top: 0;
    margin-bottom: 2.5rem;
    color: var(--color0);
}

.main-content .section-content[data-in-numbers-section="true"] .heading + div p:first-child {
    margin-top: 0;
}

.main-content .section-content[data-in-numbers-section="true"] p {
    font-size: 0.9375rem;
}

.main-content
    .section-content[data-search-section="true"]
    .search-form-container
    input[type="submit"] {
    background-image: url("/assets/media/graphics/arrow-right.svg");
    background-size: 75% 75%;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: transparent;
    border: 0;
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    margin-left: 0.5rem;
}

.main-content .in-numbers-table {
    position: relative;
    max-width: 100%;
    margin: 0 auto;
}

@media (min-width: 561px) and (max-width: 975px) {
    .main-content > div {
        box-sizing: border-box;
        width: 100%;
        padding: 2.5rem 1.25rem;
    }

    .main-content.full-width > div {
        padding: 2.5rem 0;
    }

    .main-content .section-menu {
        display: none;
    }

    .main-content .section-content {
        flex-direction: column-reverse;
        flex-wrap: wrap;
        padding: 0;
        width: 100%;
    }

    .main-content .section-content[data-indexes-section="true"] {
        flex-direction: column;
        padding: 0;
    }

    .main-content .section-content .indexes-letters {
        justify-content: flex-start;
    }

    .main-content .section-content .section-content-illustrations,
    .main-content .section-content .section-content-inner {
        box-sizing: border-box;
        width: 100%;
        margin: 0;
    }

    .main-content .section-content .section-content-inner {
        align-self: center;
        max-width: 40rem;
        padding: 0;
    }

    .main-content .section-content .section-content-illustrations {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .main-content
        .section-content[data-foreword-section="presentation"]
        .section-content-illustrations {
        display: none;
    }

    .main-content .section-content .section-content-illustrations .illustration {
        box-sizing: border-box;
        flex-grow: 1;
        text-align: center;
        padding: 1rem;
        min-width: 11.375rem;
        width: 25%;
        margin: 0;
    }

    .main-content .section-content[data-search-section="true"] {
        padding: 0 0 0 0;
    }
}

@media (max-width: 560px) {
    .main-content > div {
        box-sizing: border-box;
        width: 100%;
        padding: 2.5rem 1.25rem;
    }

    .main-content.full-width > div {
        padding: 2.5rem 0;
    }

    .main-content .section-menu {
        display: none;
    }

    .main-content .section-content {
        flex-direction: column-reverse;
        flex-wrap: wrap;
        padding: 0;
        width: 100%;
    }

    .main-content .section-content[data-indexes-section="true"] {
        flex-direction: column;
        padding: 0;
    }

    .main-content .section-content .indexes-letters {
        justify-content: flex-start;
    }

    .main-content .section-content .section-content-illustrations,
    .main-content .section-content .section-content-inner {
        box-sizing: border-box;
        width: 100%;
        margin: 0;
    }

    .main-content .section-content .section-content-inner {
        align-self: center;
        max-width: 40rem;
        padding: 0;
    }

    .main-content .section-content .section-content-illustrations {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .main-content
        .section-content[data-foreword-section="presentation"]
        .section-content-illustrations {
        display: none;
    }

    .main-content .section-content .section-content-illustrations .illustration {
        box-sizing: border-box;
        flex-grow: 1;
        text-align: center;
        padding: 1rem;
        min-width: 11.375rem;
        width: 25%;
        margin: 0;
    }

    .main-content .section-content .section-content-illustrations + .section-content-inner {
        margin-left: 0rem;
    }

    .main-content .section-content[data-magasin-section="true"] {
        padding: 0;
    }

    .main-content .section-content[data-index-section="true"] .tabs,
    .main-content .section-content[data-search-section="true"] .tabs {
        flex-direction: column;
        justify-content: flex-start;
    }

    .main-content .section-content[data-index-section="true"] .tabs .tab,
    .main-content .section-content[data-search-section="true"] .tabs .tab {
        margin: 0.4rem 0;
    }

    .main-content .section-content .extra {
        flex-wrap: wrap;
    }

    .main-content .section-content .extra .extra-thumb {
        width: 100%;
    }

    .main-content .section-content .extra .extra-metadata {
        margin: 1.4rem 0 0;
    }

    .main-content .section-content[data-search-section="true"] {
        padding: 0 0 0 0;
    }
}
