@charset "utf-8";

/* noto-serif-regular - latin-ext_latin */
@font-face {
    font-family: "Noto Serif";
    font-style: normal;
    font-weight: 400;
    src: local(""),
        url("/assets/media/fonts/noto-serif/noto-serif-v20-latin-ext_latin-regular.woff2")
            format("woff2"),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
            url("/assets/media/fonts/noto-serif/noto-serif-v20-latin-ext_latin-regular.woff")
            format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* noto-serif-700 - latin-ext_latin */
@font-face {
    font-family: "Noto Serif";
    font-style: normal;
    font-weight: 700;
    src: local(""),
        url("/assets/media/fonts/noto-serif/noto-serif-v20-latin-ext_latin-700.woff2")
            format("woff2"),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
            url("/assets/media/fonts/noto-serif/noto-serif-v20-latin-ext_latin-700.woff")
            format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* noto-serif-italic - latin-ext_latin */
@font-face {
    font-family: "Noto Serif";
    font-style: italic;
    font-weight: 400;
    src: local(""),
        url("/assets/media/fonts/noto-serif/noto-serif-v20-latin-ext_latin-italic.woff2")
            format("woff2"),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
            url("/assets/media/fonts/noto-serif/noto-serif-v20-latin-ext_latin-italic.woff")
            format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* noto-serif-700italic - latin-ext_latin */
@font-face {
    font-family: "Noto Serif";
    font-style: italic;
    font-weight: 700;
    src: local(""),
        url("/assets/media/fonts/noto-serif/noto-serif-v20-latin-ext_latin-700italic.woff2")
            format("woff2"),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
            url("/assets/media/fonts/noto-serif/noto-serif-v20-latin-ext_latin-700italic.woff")
            format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* open-sans-300 - latin-ext_latin */
@font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 300;
    src: local(""),
        url("/assets/media/fonts/open-sans/open-sans-v28-latin-ext_latin-300.woff2") format("woff2"),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
            url("/assets/media/fonts/open-sans/open-sans-v28-latin-ext_latin-300.woff")
            format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* open-sans-regular - latin-ext_latin */
@font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    src: local(""),
        url("/assets/media/fonts/open-sans/open-sans-v28-latin-ext_latin-regular.woff2")
            format("woff2"),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
            url("/assets/media/fonts/open-sans/open-sans-v28-latin-ext_latin-regular.woff")
            format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* open-sans-500 - latin-ext_latin */
@font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 500;
    src: local(""),
        url("/assets/media/fonts/open-sans/open-sans-v28-latin-ext_latin-500.woff2") format("woff2"),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
            url("/assets/media/fonts/open-sans/open-sans-v28-latin-ext_latin-500.woff")
            format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* open-sans-600 - latin-ext_latin */
@font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    src: local(""),
        url("/assets/media/fonts/open-sans/open-sans-v28-latin-ext_latin-600.woff2") format("woff2"),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
            url("/assets/media/fonts/open-sans/open-sans-v28-latin-ext_latin-600.woff")
            format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* open-sans-700 - latin-ext_latin */
@font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    src: local(""),
        url("/assets/media/fonts/open-sans/open-sans-v28-latin-ext_latin-700.woff2") format("woff2"),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
            url("/assets/media/fonts/open-sans/open-sans-v28-latin-ext_latin-700.woff")
            format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* open-sans-800 - latin-ext_latin */
@font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 800;
    src: local(""),
        url("/assets/media/fonts/open-sans/open-sans-v28-latin-ext_latin-800.woff2") format("woff2"),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
            url("/assets/media/fonts/open-sans/open-sans-v28-latin-ext_latin-800.woff")
            format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* open-sans-300italic - latin-ext_latin */
@font-face {
    font-family: "Open Sans";
    font-style: italic;
    font-weight: 300;
    src: local(""),
        url("/assets/media/fonts/open-sans/open-sans-v28-latin-ext_latin-300italic.woff2")
            format("woff2"),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
            url("/assets/media/fonts/open-sans/open-sans-v28-latin-ext_latin-300italic.woff")
            format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* open-sans-italic - latin-ext_latin */
@font-face {
    font-family: "Open Sans";
    font-style: italic;
    font-weight: 400;
    src: local(""),
        url("/assets/media/fonts/open-sans/open-sans-v28-latin-ext_latin-italic.woff2")
            format("woff2"),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
            url("/assets/media/fonts/open-sans/open-sans-v28-latin-ext_latin-italic.woff")
            format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* open-sans-500italic - latin-ext_latin */
@font-face {
    font-family: "Open Sans";
    font-style: italic;
    font-weight: 500;
    src: local(""),
        url("/assets/media/fonts/open-sans/open-sans-v28-latin-ext_latin-500italic.woff2")
            format("woff2"),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
            url("/assets/media/fonts/open-sans/open-sans-v28-latin-ext_latin-500italic.woff")
            format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* open-sans-600italic - latin-ext_latin */
@font-face {
    font-family: "Open Sans";
    font-style: italic;
    font-weight: 600;
    src: local(""),
        url("/assets/media/fonts/open-sans/open-sans-v28-latin-ext_latin-600italic.woff2")
            format("woff2"),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
            url("/assets/media/fonts/open-sans/open-sans-v28-latin-ext_latin-600italic.woff")
            format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* open-sans-700italic - latin-ext_latin */
@font-face {
    font-family: "Open Sans";
    font-style: italic;
    font-weight: 700;
    src: local(""),
        url("/assets/media/fonts/open-sans/open-sans-v28-latin-ext_latin-700italic.woff2")
            format("woff2"),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
            url("/assets/media/fonts/open-sans/open-sans-v28-latin-ext_latin-700italic.woff")
            format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* open-sans-800italic - latin-ext_latin */
@font-face {
    font-family: "Open Sans";
    font-style: italic;
    font-weight: 800;
    src: local(""),
        url("/assets/media/fonts/open-sans/open-sans-v28-latin-ext_latin-800italic.woff2")
            format("woff2"),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
            url("/assets/media/fonts/open-sans/open-sans-v28-latin-ext_latin-800italic.woff")
            format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
