@charset "utf-8";

.main-content .section-content[data-movements-section="true"] .movement {
    font-family: var(--font-family-sans-serif);
    margin-bottom: 3.25rem;
}

.main-content .section-content[data-movements-section="true"] .movement a,
.main-content .section-content[data-movements-section="true"] .movement span {
    font-size: 0.9375rem;
}

.main-content .section-content[data-movements-section="true"] .movement .title {
    font-family: var(--font-family-sans-serif);
    text-transform: uppercase;
    font-weight: 700;
    font-size: 0.9375rem;
    margin-bottom: 2.5rem;
}

.main-content .section-content[data-movements-section="true"] .movement .movement-inner {
    display: flex;
    align-items: flex-end;
}

.main-content .section-content[data-movements-section="true"] .movement .illustration img {
    box-shadow: 0 8px 20px -4px #000;
    margin-right: 4.4375rem;
}

.main-content .section-content[data-movements-section="true"] .movement .movement-inner .info {
    width: 100%;
}

.main-content .section-content[data-movements-section="true"] .movement span.heading {
    display: block;
    font-family: var(--font-family-sans-serif);
    font-weight: 700;
    font-size: 0.9375rem;
}

.main-content .section-content[data-movements-section="true"] .movement span.magazines {
    display: block;
    padding-bottom: 0.8125rem;
    border-bottom: 1px solid var(--common-color3);
}

.main-content .section-content[data-movements-section="true"] .movement .button-documentation {
    display: flex;
    justify-content: space-between;
    text-decoration: none;
    margin: 1.625rem 0;
    padding-bottom: 0.8125rem;
    border-bottom: 1px solid var(--common-color3);
}

.main-content .section-content[data-movements-section="true"] .movement .button-search {
    display: flex;
    justify-content: space-between;
    text-decoration: none;
    margin: 0;
    padding-bottom: 0.8125rem;
    border-bottom: 1px solid var(--common-color3);
}

.main-content
    .section-content[data-movements-section="true"]
    .movement
    .button-documentation
    div.button,
.main-content .section-content[data-movements-section="true"] .movement .button-search div.button {
    display: block;
    align-self: flex-end;
    width: 0.75rem;
    height: 1.25rem;
    background-image: url(/assets/media/graphics/arrow-right.svg);
    background-size: contain;
    background-repeat: no-repeat;
}

.main-content
    .section-content[data-movements-section="true"]
    .movement
    .button-documentation
    div.text,
.main-content .section-content[data-movements-section="true"] .movement .button-search div.text {
    max-width: 90%;
}
