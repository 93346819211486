@charset "utf-8";

@import "normalize.css";
@import "colors.css";
@import "typography.css";
@import "fonts.css";

@import "components/404.css";
@import "components/advanced-search-form.css";
@import "components/carousel.css";
@import "components/footer-logos.css";
@import "components/footer-menu.css";
@import "components/highlights.css";
@import "components/in-issue.css";
@import "components/magazine-bar.css";
@import "components/main-content.css";
@import "components/menu-bar.css";
@import "components/movements.css";
@import "components/secondary-nav-mobile.css";
@import "components/top-bar.css";

:root {
}

html,
body {
    box-sizing: border-box;
    font-family: var(--font-family-serif);
    font-size: var(--font-size);
    scroll-behavior: smooth;
    padding: 0;
    min-width: 100%;
    min-height: 100%;
    color: var(--common-color1);
    background-color: var(--common-color1);
}

blockquote {
    font-style: italic;
}

blockquote em {
    font-style: normal;
}

a img:hover {
    filter: brightness(70%);
}

a img {
    transition: 0.2s filter linear;
}


@media (max-width: 560px) {
}

@media (min-width: 561px) and (max-width: 975px) {
}
