@charset "utf-8";

.magazine-secondary-nav-wrapper {
    display: none;
    margin: 0 auto;
    font-family: var(--font-family-sans-serif);
    font-size: 0.8125rem;
}

.magazine-secondary-nav-wrapper, .secondary-nav-select {
    width: 100%;
    border: 0;
}

.magazine-secondary-nav-wrapper, .secondary-nav-select {
    box-sizing: border-box;
    padding: .4rem;
    width: 100%;
}

@media (min-width: 561px) and (max-width: 975px) {
    .magazine-secondary-nav-wrapper {
        width: 66.666%;
        max-width: 16rem;
        min-width: unset;
        padding: 0.875rem 0 0;

    }

    .magazine-secondary-nav-wrapper {
        display: block;
    }
}

@media (max-width: 560px) {
    .magazine-secondary-nav-wrapper {
        width: 66.666%;
        max-width: 16rem;
        min-width: unset;
        padding: 0.875rem 0 0;
    }

    .magazine-secondary-nav-wrapper {
        display: block;
    }
}
