@charset "utf-8";

#menu-bar {
    background-color: var(--common-color1);
    font-family: var(--font-family-sans-serif);
    font-size: 0.8125rem;
    text-transform: uppercase;
}

#menu-bar > div {
    display: flex;
    box-sizing: border-box;
    width: 60rem;
    margin: 0 auto;
    padding: 0.4rem 0 0.4rem 0;

}

#menu-bar > div .site-selector {
    color: var(--common-color0);
    padding-left: 2rem;
    align-self: center;
}

#menu-bar > div .site-selector a {
    color: var(--common-color3);
    text-decoration: none;
    padding: 0 0.3rem;
}

#menu-bar > div .site-selector a.selected {
    color: var(--common-color0);
    font-weight: 700;
}

#menu-bar > div .site-selector a:hover {
    color: var(--common-color0);
}

#menu-bar > div .menu, #menu-bar > div .menu-responsive {
    text-align: right;
    flex-grow: 1;
    align-self: center;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: .625rem;
}

#menu-bar > div .menu a, #menu-bar > div .menu-responsive a {
    color: var(--common-color3);
    text-decoration: none;
    padding: 0 0 0 1rem;
}

#menu-bar > div .menu a.selected,
#menu-bar > div .menu a:hover,
#menu-bar > div .menu-responsive a.selected {
    color: var(--common-color0);
}

#menu-bar > div .menu-responsive {
    position: absolute;
    box-sizing: border-box;
    background-color: var(--common-color1);
    top: 0;
    left: -150vw;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: left;
    padding-left: 6.25rem;
    transition: left 0.75s ease-in-out;
}

#menu-bar > div .menu-responsive a:not(:first-child) {
    margin-top: 1.625rem;
}

#menu-bar > div .menu-responsive .close-menu {
    width: calc(100% - 3.25rem - 1.25rem);
    text-align: right;
}

#menu-bar > div .menu-responsive .close-menu svg {
    width: 1.25rem;
    height: 1.25rem;
}

#menu-bar > div .menu-responsive-call {
    align-self: center;
    display: none;
    flex-grow: 1;
    justify-content: flex-end;
    margin-right: .625rem;
}

#menu-bar > div .menu-responsive-call .menu-responsive-caller {
    cursor: pointer;
    background-color: transparent;
    border: 0;
}

body.responsive-menu {
    overflow: hidden;
}

body.responsive-menu #menu-bar > div .menu-responsive {
    left: 0;
}

@media (min-width: 561px) and (max-width: 975px) {
    #menu-bar > div {
        width: 100%;
        padding: 0.4rem 0.625rem 0.4rem 0.625rem;
    }

    #menu-bar > div .menu {
        display: none;
    }

    #menu-bar > div .menu-responsive-call {
        display: flex;
    }
}

@media (max-width: 560px) {
    #menu-bar > div {
        width: 100%;
        padding: 0.4rem 0.625rem 0.4rem 0.625rem;
    }

    #menu-bar > div .menu {
        display: none;
    }

    #menu-bar > div .menu-responsive-call {
        display: flex;
    }

    #menu-bar > div .menu-responsive {
        padding-left: 3.25rem;
    }
}
