@charset "utf-8";

#footer-menu {
    background-color: var(--common-color1);
    color: var(--common-color3);
    font-family: var(--font-family-sans-serif);
    font-size: 0.8125rem;
}

#footer-menu > div {
    display: flex;
    box-sizing: border-box;
    width: 60rem;
    margin: 0 auto;
    padding: 2.5rem 0.625rem 2.25rem 0.625rem;
}

#footer-menu .logos {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

#footer-menu .logos .premio span {
    display: block;
    padding: 0.6rem 0;
    color: var(--common-color0);
}

#footer-menu .foot-menu {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    flex-wrap: wrap;
}

#footer-menu .foot-menu .foot-menu-column {
    margin-left: 3rem;
}

#footer-menu .foot-menu .foot-menu-column a,
#footer-menu .foot-menu .foot-menu-column a:link,
#footer-menu .foot-menu .foot-menu-column a:visited,
#footer-menu .foot-menu .foot-menu-column a:active {
    text-decoration: none;
    color: var(--common-color3);
    display: block;
    margin-bottom: 0.6rem;
}

#footer-menu .foot-menu .foot-menu-column a:hover {
    color: var(--common-color0);
}

#footer-menu .foot-menu .foot-menu-column a.header {
    font-weight: 700;
    text-transform: uppercase;
}

@media (min-width: 561px) and (max-width: 975px) {
    #footer-menu {
        display: none;
    }
}

@media (max-width: 560px) {
    #footer-menu {
        display: none;
    }
}
